module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-baidu-analytics/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"03e24c399767a4fa70dfdfa79bebb536","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[],"debug":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#e1e1e1","theme_color":"#e1e1e1","display":"standalone","icon":"src/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b20f42a7a1a4c8d1d862fed4611f1086"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
